import React from 'react';
import classNames from 'classnames';
import propTypes, { defaultProps } from './propTypes';

const Left = ({
  children,
  className,
  outerClassName,
  innerClassName,
  fluid,
}) => (
  <div
    className={classNames('content-section__left', className, {
      'content-section__left--fluid': fluid,
    })}
  >
    {!fluid ? (
      <div
        className={classNames(
          'content-outer',
          'content-outer--left',
          outerClassName
        )}
      >
        <div
          className={classNames(
            'cf',
            'content-inner',
            'content-inner--left',
            innerClassName
          )}
        >
          {children}
        </div>
      </div>
    ) : (
      children
    )}
  </div>
);

Left.propTypes = propTypes;
Left.defaultProp = defaultProps;

export default Left;

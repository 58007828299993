import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Col = ({ children, width, className }) => (
  <div className={classNames('row__col', `row__col--${width}`, className)}>
    {children}
  </div>
);

Col.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

Col.defaultProps = {
  children: null,
  width: 12,
  className: null,
};

export default Col;

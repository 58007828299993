import React from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';
import './index.scss';

const transTime = 0.25;

class BurgerIcon extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
  };

  static defaultProps = {
    open: false,
  };

  componentDidMount() {
    this.animation = new TimelineLite({ paused: true })
      .to(
        this.topBarRef.current,
        transTime,
        {
          y: this.iconRef.current.clientHeight / 2 - 1,
          rotationZ: 45,
        },
        'f0',
      )
      .to(
        this.botBarRef.current,
        transTime,
        {
          y: -this.iconRef.current.clientHeight / 2 + 1,
          rotationZ: -45,
        },
        'f0',
      )
      .to(this.midBarRef.current, transTime, { rotationZ: 45 }, 'f0')
      .add('end');

    if (this.props.open) {
      this.animation.seek('end');
    } else {
      this.animation.seek(0);
	}
	
  }

  componentDidUpdate(prevProps) {
    if (this.animation && prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.animation.play();
      } else {
        this.animation.reverse();
      }
    }
  }

  animation = null;
  iconRef = React.createRef();
  topBarRef = React.createRef();
  midBarRef = React.createRef();
  botBarRef = React.createRef();

  render() {
    return (
      <span className="burger-icon" ref={this.iconRef}>
        <hr
          className="burger-icon__bar burger-icon__bar--top"
          ref={this.topBarRef}
        />
        <hr
          className="burger-icon__bar burger-icon__bar--mid"
          ref={this.midBarRef}
        />
        <hr
          className="burger-icon__bar burger-icon__bar--bot"
          ref={this.botBarRef}
        />
      </span>
    );
  }
}

export default BurgerIcon;

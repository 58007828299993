import React from 'react';
import './index.scss';

const Footer = () => (
  <footer className="Footer">
    <div className="content-outer">
      <div className="content-inner">
        <ul className="Footer__nav list list--inline list--no-style">
          <li className="Footer__nav__item">
            <a
              href="//facebook.com/eyeonattraction"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li className="Footer__nav__item">
            <a
              href="//twitter.com/eyeonattraction"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li className="Footer__nav__item">
            <a
              href="//youtube.com/eyeonattractiontv"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>
          </li>
          <li className="Footer__nav__item">
            <a
              href="mailto:eyeonattraction@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;

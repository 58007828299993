import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ParallaxBackground from '/src/components/atoms/ParallaxBackground';
import Left from './sides/left';
import Right from './sides/right';
import './index.scss';

export { Left, Right };

const ContentSection = ({
  splash,
  parallax,
  fullPage,
  dual,
  banner,
  center,
  header,
  light,
  children,
  bgSrc,
}) => {
  const Wrapper = header ? 'header' : 'section';

  return (
    <Wrapper
      className={classNames('content-section', {
        'content-section--splash': splash,
        'content-section--full-page': fullPage,
        'content-section--banner': banner,
        'content-section--light': light,
        'content-section--dual': dual,
      })}
    >
      {(splash || banner) &&
        (bgSrc && (
          <div className="content-section__bg">
            <ParallaxBackground
              bgSrc={bgSrc}
              offset={parallax ? undefined : 0}
            />
          </div>
        ))}
      <div className={classNames({ 'content-outer': !dual })}>
        <div
          className={classNames('content-section__content', {
            'content-inner': !dual,
            tc: center,
          })}
        >
          {children}
        </div>
      </div>
    </Wrapper>
  );
};

ContentSection.propTypes = {
  banner: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
  light: PropTypes.bool,
  dual: PropTypes.bool,
  header: PropTypes.bool,
  splash: PropTypes.bool,
  parallax: PropTypes.bool,
  fullPage: PropTypes.bool,
  splashBgUrl: PropTypes.string,
};

ContentSection.defaultProps = {
  splash: false,
  parallax: true,
  fullPage: false,
  banner: false,
  center: false,
  header: false,
  light: false,
  dual: false,
  children: null,
  splashBgUrl: null,
};

export default ContentSection;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Col from './Col';
import './index.scss';

const GridRow = ({ children, vertGutter, className, center }) =>
  children ? (
    <div
      className={classNames(
        'row',
        {
          'row--space-v': !!vertGutter,
          'row--center': !!center,
        },
        className
      )}
    >
      {children}
    </div>
  ) : null;

GridRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  vertGutter: PropTypes.bool,
  center: PropTypes.bool,
};

GridRow.defaultProps = {
  children: null,
  className: null,
  vertGutter: false,
  center: false,
};

export { GridRow as Row, Col };

export default GridRow;

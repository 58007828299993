import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '/src/components/molecules/TopBar';
import Footer from '/src/components/molecules/Footer';

const Page = ({ title, children }) => (
  <>
    <a className="sr-only sr-only-focusable" href="#content-main">
      Skip to Main Content
    </a>
    <TopBar />
    <main role="main" className="content-main" id="content-main">
      {children}
    </main>
    <Footer />
  </>
);

Page.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  hideHeader: PropTypes.bool,
};

Page.defaultProps = {
  className: null,
  children: null,
  hideHeader: false,
};

export default Page;

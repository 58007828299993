import PropTypes from 'prop-types';

export default {
  children: PropTypes.node,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  fluid: PropTypes.bool,
};

export const defaultProps = {
  children: null,
  className: null,
  outerClassName: null,
  innerClassName: null,
  fluid: false,
};

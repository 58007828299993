import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';
import { compose, withState, withHandlers } from 'recompose';
import classNames from 'classnames';

import { selectors } from '/src/store/cart';
import BurgerIcon from '/src/components/atoms/BurgerIcon';

import './index.scss';

const mainNavItems = [
  {
    id: 'method-show',
    title: 'Order the CD',
    url: '/shop/products/the-method-cd',
  },
  // {
  //   id: 'home',
  //   title: 'Home',
  //   url: '/',
  // },
  // {
  //   id: 'shop',
  //   title: 'Shop',
  //   url: '/shop',
  // },
  // {
  //   id: 'music',
  //   title: 'Music',
  //   url: '/music',
  // },
  // {
  //   id: 'media',
  //   title: 'Media',
  //   url: '/media',
  // },
  // {
  //   id: 'about',
  //   title: 'About',
  //   url: '/intro',
  // },
  // {
  //   id: 'booking',
  //   title: 'Booking',
  //   url: '/booking',
  // },
];

const TopBar = ({ mobileNavOpen, toggleMobileNavOpen, cartSize }) => {
  const { isLoggedIn } = useIdentityContext();

  return (
    <header className="TopBar">
      <div className={classNames('content-outer', 'TopBar__outer-wrapper')}>
        <div className={classNames('content-inner', 'TopBar__inner-wrapper')}>
          <div className="TopBar__logo-section">
            <Link
              to="/"
              className="TopBar__nav-list__link TopBar__nav-list__link--logo"
            >
              Eye on Attraction
            </Link>
          </div>
          <div
            className={classNames('TopBar__nav-section', {
              'TopBar__nav-section--open': mobileNavOpen,
            })}
          >
            <div
              className={classNames('TopBar__nav-section__burger-wrap', 'tr')}
            >
              <button
                className="TopBar__nav-section__burger-wrap__button"
                onClick={toggleMobileNavOpen}
                aria-label="Open main navigation"
              >
                <BurgerIcon open={mobileNavOpen} />
              </button>
            </div>
            <nav className="TopBar__nav-section__nav-wrap">
              <ul className={classNames('TopBar__nav-list', 'main-nav')}>
                {mainNavItems.map(navItem => (
                  <li key={navItem.id} className="TopBar__nav-list__item">
                    <Link
                      to={navItem.url}
                      className="TopBar__nav-list__link"
                      {...(navItem.newWindow && { target: '_blank' })}
                    >
                      {navItem.title}
                    </Link>
                  </li>
                ))}
                <li className="TopBar__nav-list__item">
                  <Link to="/shop/cart" className="TopBar__nav-list__link">
                    <span>Cart</span>
                    <span>{cartSize ? ` (${cartSize})` : ''}</span>
                    <span className="sr-only">Items in cart</span>
                  </Link>
                </li>
                {isLoggedIn && (
                  <li className="TopBar__nav-list__item">
                    <Link to="/shop/admin" className="TopBar__nav-list__link">
                      Store Admin
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

TopBar.propTypes = {
  mobileNavOpen: PropTypes.bool.isRequired,
  toggleMobileNavOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setMobileNavOpen: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  cartSize: selectors.cartSizeSelector,
});

export default compose(
  connect(mapStateToProps),
  withState('mobileNavOpen', 'setMobileNavOpen', false),
  withHandlers({
    toggleMobileNavOpen: ({ setMobileNavOpen }) => () =>
      setMobileNavOpen(mobileNavOpen => !mobileNavOpen),
  })
)(TopBar);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const Button = React.forwardRef(
  (
    {
      component,
      children,
      className,
      disabled,
      secondary,
      small,
      cta,
      link,
      color,
      bypass,
      ...buttonProps
    },
    ref
  ) => {
    const ButtonComponent = component || 'button';

    return (
      <ButtonComponent
        className={classNames(className, {
          button: !bypass,
          'button--disabled': !!disabled,
          'button--secondary': !!secondary,
          'button--small': !!small,
          'button--cta': !!cta,
          'button--link': !!link,
          [`button--${color}`]: !!color,
        })}
        {...{ ...buttonProps, disabled }}
        ref={ref}
      >
        {children}
      </ButtonComponent>
    );
  }
);

Button.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  bypass: PropTypes.bool,
  small: PropTypes.bool,
  cta: PropTypes.bool,
  link: PropTypes.bool,
};

Button.defaultProps = {
  component: 'button',
  children: null,
  className: null,
  color: null,
  disabled: false,
  secondary: false,
  bypass: false,
  small: false,
  cta: false,
  link: false,
};

export default Button;

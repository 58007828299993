import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

class ParallaxBackground extends React.Component {
  static propTypes = {
    bgSrc: PropTypes.string,
    offset: PropTypes.number,
  };

  static defaultProps = {
    bgSrc: null,
    offset: 400,
  };

  state = {
    containerTop: 0,
    containerHeight: 0,
    adjustTop: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.watchScroll);
    window.addEventListener('resize', this.calcPositions);
    this.watchScroll();
    this.calcPositions();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.watchScroll);
    window.removeEventListener('resize', this.calcPositions);
  }

  watchScroll = () => {
    const { offset } = this.props;
    const { containerTop, containerHeight, viewportHeight } = this.state;

    const scrollRatio =
      (window.scrollY - containerTop + viewportHeight) /
        (containerHeight + viewportHeight) -
      0.5;

    this.setState({
      adjustTop: window.scrollY - containerTop - offset * scrollRatio,
    });
  };

  calcPositions = () =>
    this.setState(
      {
        containerTop:
          window.scrollY +
          this.bgRef.current.parentNode.getBoundingClientRect().top,
        containerHeight: this.bgRef.current.parentNode.clientHeight,
        viewportHeight: window.innerHeight,
      },
      this.watchScroll
    );

  bgRef = React.createRef();

  render() {
    const { bgSrc, offset } = this.props;
    const { adjustTop } = this.state;

    return (
      <img
        src={bgSrc}
        alt=""
        ref={this.bgRef}
        className="ParallaxBackground"
        style={{
          minHeight: `calc(100% + ${offset}px)`,
          transform: `translate(-50%, -50%) rotateX(0) translateY(${adjustTop ||
            0}px)`,
        }}
      />
    );
  }
}

export default ParallaxBackground;

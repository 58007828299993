import React from 'react';
import classNames from 'classnames';
import propTypes, { defaultProps } from './propTypes';

const Right = ({
  children,
  className,
  outerClassName,
  innerClassName,
  fluid,
}) => (
  <div
    className={classNames('content-section__right', className, {
      'content-section__right--fluid': fluid,
    })}
  >
    {!fluid ? (
      <div
        className={classNames(
          'content-outer',
          'content-outer--right',
          outerClassName
        )}
      >
        <div
          className={classNames(
            'cf',
            'content-inner',
            'content-inner--right',
            innerClassName
          )}
        >
          {children}
        </div>
      </div>
    ) : (
      children
    )}
  </div>
);

Right.propTypes = propTypes;
Right.defaultProps = defaultProps;

export default Right;
